<template>
  <el-row type="flex" justify="center">
    <el-col :sm="10" :lg="8">
      <h1>{{ $t('login.title') }}</h1>
      <div class="message">{{ $t('login.information') }}</div>

      <el-alert v-if="error" type="error" :title="$t('login.error') + ': ' + error" :closable="false"/>
      <el-form @submit.prevent.native="checkEnter" v-loading="loading">
        <label class="form-item">{{ $t('login.email') }}</label>
        <el-input
          v-model="email" type="text"
          @blur="preLogin()"
          auto-complete="username"/>
        <label class="form-item">{{ $t('login.password') }}</label>
        <el-input
          v-model="password"
          type="password"
          ref="password"
          auto-complete="current-password"/>
        <label class="form-item">{{ $t('login.application') }}</label>
      </el-form>
      <div>
        <el-select v-model="application_id" autoComplete="off">
          <el-option
            v-for="app in applications"
            :key="app.id"
            :label="app.title"
            :value="app.id">
          </el-option>
        </el-select>
        <label class="form-item">Lecture seule</label>
        <el-switch v-model="readonly" @change="updateReadOnly()"/>
        <br/>
        <br/>
        <el-button type="primary" class="login-button" @click="login" :disabled="!canLogin">{{ $t('login.login') }}</el-button>
      </div>
    </el-col>
  </el-row>
</template>

<script>

import store from '../store'
import router from '../router'

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      error: null,
      readonly: false,
      email: '',
      password: '',
      application_id: null,
      applications: null
    }
  },

  beforeRouteEnter (to, from, next) {
    if (store.state.user !== null) {
      router.push({name: 'index'})
    } else {
      next()
    }
  },

  computed: {
    canLogin: function () {
      return this.email !== '' && this.password !== '' && this.application_id !== null
    }
  },

  methods: {
    reset: function () {
      this.application_id = null
      this.applications = null
    },
    checkEnter: function () {
      if (this.application_id === null) return this.preLogin()
      return this.login()
    },
    preLogin: function () {
      this.reset()
      if (this.email === '') {
        return
      }
      this.loading = true
      this.error = null
      this.$store.dispatch('preauth', {
        email: this.email
      }).then((resp) => {
        this.loading = false
        if (resp.data.apps.length === 0) {
          this.error = 'Utilisateur inconnu'
          this.applications = null
        } else {
          this.applications = resp.data.apps
          this.application_id = this.applications[0].id
          this.focusPassword()
        }
      }).catch((error) => {
        this.error = error
        this.loading = false
      })
    },

    async focusPassword () {
    },

    login: function () {
      event.preventDefault()
      if (this.email === '' || this.password === '' || this.application_id === null) {
        return
      }
      this.loading = true
      this.error = null
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
        application_id: this.application_id
      }).then((resp) => {
        if (this.$store.state.user.is_staff !== true) {
          this.loading = false
          this.$store.commit('logout')
          this.error = 'Vous ne disposer pas des droits nécessaire à l\'administration de cette application'
          return
        }
        if (this.$store.state.user.is_superuser === true) {
          this.loading = false
          this.$router.push({name: 'admin-apps'})
          // this.$store.commit('logout')
          // this.error = 'Vous ne pouvez pas utiliser cette interface en temps que Super Utilisateur pour le moment.'
          return
        }
        this.$store.dispatch('loadUserInfo', {
          appID: this.$store.state.user.application_id,
          userID: this.$store.state.user.user_id
        }).then((resp) => {
          this.loading = false
          this.$router.push({name: 'index'})
          this.$message({
            message: `Bienvenue ${this.$store.state.userInfo.name}`,
            type: 'success'
          })
        }).catch(() => {
          this.loading = false
          this.$store.commit('logout')
          this.error = 'Unable to load user'
        })
      }).catch((error) => {
        this.error = error
        this.loading = false
      })
    },
    updateReadOnly: function (event) {
      this.$store.state.readonly = event
    }
  },

  mounted () {
    this.readonly = this.$store.state.readonly
  }
}
</script>

<style scoped>
h1, .message {
  margin-bottom: 20px;
}
.el-select {
  width: 100%;
}
</style>
