<template>
    <div class="">
    </div>
</template>

<script>

export default {
  name: 'Index',
  created () {
    let state = this.$store.state
    if (state.user === null) {
      this.$router.push({path: '/auth'})
    } else if (state.user.application_id > 1) {
      this.$router.push({
        name: 'appindex',
        params: {
          appID: state.user.application_id
        }
      })
    } else {
      this.$router.push({path: '/admin/apps'})
    }
  }
}
</script>

<style scoped>
</style>
